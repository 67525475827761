import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import {
  postItem,
  postTitle,
  titleLink,
  postDate,
  excerpt,
  paginate
} from './blog-index.module.css';

const BlogIndex = ({ data, pageContext }) => (
  <Layout>
    <div>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <article className={postItem} key={node.fields.slug}>
          <h3 className={postTitle}>
            <Link to={node.fields.slug} className={titleLink}>
              {node.frontmatter.title}
            </Link>
          </h3>
          <div className={postDate}>{node.frontmatter.date}</div>
          <p
            className={excerpt}
            dangerouslySetInnerHTML={{
              __html: node.excerpt
            }}
          />
        </article>
      ))}
    </div>
    <div className={paginate}>
      <div>
        {pageContext.currentPage > 0 && (
          <Link
            to={
              pageContext.currentPage === 1
                ? '/'
                : `/${pageContext.currentPage - 1}`
            }
          >
            上一页
          </Link>
        )}
      </div>
      <div>
        第 {pageContext.currentPage + 1} 页 / 共 {pageContext.numPages} 页
      </div>
      <div>
        {pageContext.currentPage < pageContext.numPages - 1 && (
          <Link to={`/${pageContext.currentPage + 1}`}>下一页</Link>
        )}
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  query IndexQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { fields: { type: { eq: "post" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          fileAbsolutePath
          excerpt(pruneLength: 280, truncate: true)
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
          }
          fields {
            slug
            type
          }
        }
      }
    }
  }
`;

export default BlogIndex;
